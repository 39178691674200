import React, { useEffect, useRef, useMemo, useState, useContext } from "react";
import { Link, useNavigate, useLoaderData } from "react-router-dom";
import { address } from "../../api/vars";
import { Parallax } from "react-parallax";
import PicTop from "../../assets/background-01.jpg";
import TopPanel from "../navbar/toppanel";
import Section1 from "./section1a";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import Section7 from "./section7";
import Section8 from "./section8";
import Footer from "../footer/footer";
import BannerText from "./homebannertext";
import AppContext from "../../context/appcontext";
const Main = () => {
  const Context = useContext(AppContext);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const map = useRef(null);
  const Navigate = useNavigate();
  useEffect(() => {
    if (+Context.loginTab !== 2 && +Context.loginTab !== 1) {
      Context.ChangeTab(0);
      window.scrollTo(0, 0);
    }
  }, [Context.loginTab]);
  // useEffect(() => {
  //   const node = ref.current;
  //   node.style.opacity = 0.5; // Trigger the animation
  //   console.log(node);
  //   return () => {
  //     node.style.opacity = 0; // Reset to the initial value
  //   };
  // }, [ref]);

  // const insideStyles = {
  //   background: "white",
  //   padding: 20,
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%,-50%)",
  // };
  return (
    <div id="main" className="mx-auto overflow-x-hidden pagefade">
      <Parallax
        bgImage={PicTop}
        blur={0}
        strength={300}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, .3)`,
              // left: '50%',
              // top: '50%',
              width: "100vw",
              height: "100%",

              overflow: "hidden",
            }}
          />
        )}
      >
        <div style={{ height: "100vh" }}>
          <TopPanel />
          <BannerText />
          {/* <div style={insideStyles}>Reverse direction</div> */}
        </div>
      </Parallax>

      <section className="containe">
        <Section1 />
      </section>
      <section>
        <Section2 />
      </section>

      <section className="  px-2 px-sm-0 contaier py-3 ">
        <div className="fs-4 fw-lighter text-center text-dark-emphasis  col-12 col-sm-10 col-md-8  mx-auto  user-select-none  ">
          Need Hands is one of the best Medical Support Provider in your area.
          All our support staff are highly skilled, motivated, trained &
          certified so that you get the best support at your place. We are just
          a call away. Book your appointment{" "}
          <a
            href={"tel:" + address.phone}
            className="text-decoration-none fw-bold text-primary"
          >
            now
          </a>{" "}
          to see the difference.
        </div>
        {/* <hr className="w-50 mx-auto " /> */}
      </section>
      <section id="ourservices" className="  px-3 bg-light">
        <Section3 />
      </section>
      <section id="b" className=" pt-4 px- d-none " ref={ref2}>
        <Section4 />
      </section>
      <section className=" pt-4 px-3 bg-ligh pb-3 ">
        <Section5 />
      </section>
      <section className="  py-4 bg-light">
        <Section6 />
      </section>
      <section className="  px-3 bg-light " id="map">
        <Section7 />
      </section>
      <section className="pt-5  px-3  " id="gettogeher">
        <Section8 />
      </section>
      <Footer />
    </div>
  );
};

export default Main;
