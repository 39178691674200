import axios from "axios";

export const MailAxios = axios.create({
  baseURL: "https://needhands.com.au/",
});

export const MailerWithAttachment =
  "https://needhands.com.au/mail/mailwithattach.php";

export const Mailer = "https://needhands.com.au/mail/mailapi.php";
