import React from "react";
import Team1 from "../../assets/team-01.jpeg";
import Team2 from "../../assets/team-02.jpeg";
import Team3 from "../../assets/team-03.jpeg";
import Team4 from "../../assets/team-04.jpeg";

const Section4 = () => {
  function MakeCard(param) {
    return (
      <div className="col card border-0 rounded-0 ">
        <div className="position-relative">
          <img
            src={param.image}
            className="card-img-top rounded-0 shadow "
            alt="..."
          />
          <div
            className="position-absolute start-50 translate-middle-x w-100 text-center"
            style={{ bottom: "10%" }}
          >
            <div className="card-title  text-white title display-5  text-shadow-black">
              {param.title}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="card-subtitle ff1  fs-4 mb-2  text-shadow-grey">
            {param.subtitle}
          </div>
          <div className="card-text  ff1 fs-4 fw300">
            {param.text} &nbsp;
            <a
              href={param.link}
              className="card-link position-absolte botom-0"
              style={{ textDecoration: "none" }}
            >
              read more ...
            </a>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="">
      <div className="px-3">
        <div className=" display-5 mb-3 df1  ">
          <span className="text-warning text-shadow-black">Caregiving</span>{" "}
          Team
        </div>
        <div className=" row row-cols-1 row-cols-s-2 mb-3">
          <div className=" fs-3 ff1 fw300">
            Powering the most comprehensive services of retirement communities
            in United States, Vivo Retirement Home enables families to research
            and compare profiles to find the best choice for their loved ones.
          </div>
          <div className=" mt-auto ms-auto align-ed text-end">
            <a
              href="/#"
              type="button"
              className="btn btn-warning btn-sm rounded-0 px-3 ms-auto  text-middle"
            >
              Join Our Team
            </a>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4  g-4">
        {MakeCard(itemList[0])}
        {MakeCard(itemList[1])}
        {MakeCard(itemList[2])}
        {MakeCard(itemList[3])}
      </div>
    </div>
  );
};

const itemList = [
  {
    image: Team1,
    subtitle: "Founder, Caregiver",
    title: "Emily Abbott",
    text: "Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive.",
    link: "/#",
  },
  {
    image: Team2,
    subtitle: "Caregiver",
    title: "Caregiver",
    text: "Quickly cultivate optimal processes and tactical architectures. Completely iterate covalent strategic theme areas via accurate e-markets.",
    link: "/#",
  },
  {
    image: Team3,
    subtitle: "Caregiver",
    title: "Tyrone Armstrong",
    text: "Completely synergize scalable e-commerce rather than high standards in e-services, after leading-edge intellectual capital.",
    link: "/#",
  },
  {
    image: Team4,
    subtitle: "Caregiver",
    title: "Veronica Vaughn",
    text: "Disability Care service is something that becomes extremely important for people who are deprived of normal life due to illnesses or accidents.",
    link: "/#",
  },
];
export default Section4;
