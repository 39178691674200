import React, { useState, useEffect } from "react";
import { address } from "../../api/vars";

const ContactBannertext = () => {
  const [windowWidth, setWindowWidth] = useState(1000);

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      className="w-10 ps-2 ps-sm-5 user-select-none d-flex align-items-cener flex-column  text-white position-absolute  start-0 "
      style={{ width: "100%", top: "45%", height: "50vh" }}
    >
      <div className="">
        <div
          className="display-1 fw-bold text-white  text-shadow-black  "
          style={{ fontSize: (windowWidth + 155) / 22 }}
        >
          Care meets the <span className="text-warning">Quality here</span>
        </div>
      </div>
      <div className="text-white mt-1   text-shadow-blac  ">
        <span
          className="   text-shadow-gre "
          style={{
            fontSize: (windowWidth + 600) / 67,
          }}
        >
          Because you deserve the best !
          {/* <br className="d-block d-sm-none" /> */}
          {/* <b className="text-shadow-whit"> NeedHands Nursing Agency</b> */}
        </span>
      </div>
    </div>
  );
};

export default ContactBannertext;
