export const NavItems = [
  { id: "", icon: "", text: "Home", link: "/", linkType: "no-hash" },
  {
    id: "",
    icon: "",
    text: "About us",
    link: "/aboutus",
    linkType: "hash",
  },
  {
    id: "",
    icon: "",
    text: "Services",
    link: "/#ourservices",
    linkType: "hash",
  },
  { id: "", icon: "", text: "Join us", link: "/jobs", linkType: "no-hash" },
  {
    id: "",
    icon: "",
    text: "Contact us",
    link: "/contactus",
    linkType: "no-hash",
  },
];
