// import logo from './logo.svg';
// import './App.css';
// import Map from "./screens/home/section7";
// import Home from "./screens/home/home";
import { useContext } from "react";
// import Test from "./test/test";
import MyFonts from "./styles/fonts";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import Main from "./screens/home/main";
import Navbar from "./screens/navbar/navbar";
// import Section1 from "./screens/home/section1";
import Map from "./screens/home/showmap";
import Fonts from "./styles/fonts";
import AboutUs from "./screens/aboutus/aboutus";
import ContactUs from "./screens/contactus/contactform";
import JoinUs from "./screens/joinus/joinus";
import Context from "./context/appcontext";
import AppContextProvider from "./context/contextprovider";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,

      // errorElement: <ErrorPage />,
    },
    {
      path: "/aboutus",
      element: <AboutUs />,

      // errorElement: <ErrorPage />,
    },
    {
      path: "/contactus",
      element: <ContactUs />,

      // errorElement: <ErrorPage />,
    },
    {
      path: "/jobs",
      element: <JoinUs />,

      // errorElement: <ErrorPage />,
    },
  ]);

  // return <Test />;
  return (
    <div>
      <AppContextProvider>
        <RouterProvider router={router} />
        {/* <Main /> */}
        {/* <Fonts /> */}
        {/* <Agharn /> */}
      </AppContextProvider>
    </div>
  );
}

export default App;
