import React, { use, useEffect, useState } from "react";
import Nurse1 from "../../assets/nurses1.jpeg";
import MedSupport from "../../assets/home-section-2.jpg";
import { Parallax } from "react-parallax";
import { address } from "../../api/vars";
const Section2 = () => {
  const [windowWidth, setWindowWidth] = useState(1000);

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const itemList = [
    {
      icon: "bi-calendar-plus",
      title: "24x7 Service",
      text: (
        <span>
          We are always a call away, just pick up your phone and call
          <a
            className="text-primary text-decoration-none text-shadow-blac lead "
            href={"tel:" + address.phone}
          >
            {" "}
            {address.phone}.{" "}
          </a>
          We provide best Medical care professionals on call or on demand.
        </span>
      ),
      link: "/#",
      linkText: "Find more",
    },
    {
      icon: "bi-person-check",
      title: "Trained Professional",
      text: (
        <span>
          All our Medical support staff are highly skilled, IPC trained,
          motivated and certified to give the best care that your residents
          deserve while going through the pandemic.
        </span>
      ),
      link: "/#",
      linkText: "Find more",
    },
  ];
  //   const random1 = Math.floor(Math.random() * ItemList.length);
  //   const random2 = random1 === 1 ? 0 : 1;
  //   return <div>this is new slide</div>;
  return (
    <div className=" user-select-none ">
      <Parallax
        className="border border-2 border-light p-0"
        bgImage={MedSupport}
        // blur={{ min: -1, max: 1 }}
        strength={-100}
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, .3)`,
              // left: '50%',
              // top: '50%',
              width: "100%",
              height: "100%",
            }}
          />
        )}
        style={{ width: "100vw", height: "100vh" }}
      >
        <div
          className="vh-100 d-flex flex-column align-items-start   "
          style={{ width: "100vw" }}
        >
          <div
            className=" display-6  text-warning text-shadow-black mx-auto   text-center  mt-3"
            style={{ fontSize: (windowWidth + 400) / 36 }}
          >
            When your loved ones require care, <br />
            <span className="text-shadow-dark text-light">
              {" "}
              we are just a call away.
            </span>
          </div>

          <div className=" text-whit mt-auto   text-center  mb-5 w-100">
            <div className="row row-cols-sm-2  ">
              {/* <div style={{ height: "100%" }}>{MakeCard(itemList[0])}</div> */}
              {/* <div style={{ height: "100%" }}>{MakeCard(itemList[1])}</div> */}
              {MakeCard(itemList[0])}
              {MakeCard(itemList[1])}
            </div>
          </div>
        </div>
        <div className="   " style={{ width: "100vw" }}></div>
      </Parallax>
    </div>
  );
};

function MakeCard(param) {
  return (
    <div
      className="card-boy d-flex flex-column align-content-between mb-3"
      // style={{ height: "350px" }}
    >
      <div className=" mx-auto">
        <i
          className={`bi ${param.icon} display-5 text-white text-shadow-black`}
        ></i>
      </div>
      <div className="card-titl mx-auto  text-white h3 text-shadow-black">
        {" "}
        {param.title}
      </div>
      <div
        className="card-tet text-white mx-auto fs-4 fw-light  mb-0 px-3 "
        style={{
          height: "100%",
          // background: `rgba(0, 0, 0, .3)`
        }}
      >
        {param.text}
      </div>
      <div className="mt-auto pt-3">
        <a
          href="/#"
          className="btn btn-warning fw-bold mx-auto    shadow-3 rounded-0 "
        >
          {param.linkText}
        </a>
      </div>
    </div>
  );
}

export default Section2;
