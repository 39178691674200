import React, { useContext, useRef, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import "./navclass.css";
import { NavItems } from "./navitems";
import Logo from "../../assets/nh-logo-transparent-sm.png";
import { Offcanvas } from "bootstrap";
import AppContext from "../../context/appcontext";
import { address } from "../../api/vars";
const Navbar = (props) => {
  const Context = useContext(AppContext);
  const closebtn = useRef();
  const [selectedItem, setSelectedItem] = useState(Context.loginTab);
  const itemID = ["Home", "About us", "Services", "Join us", "Contact"];
  var nav;
  return (
    <div className=" ">
      <nav className="navbar  navbar-expand-md p-0">
        <div className="container-fluid ">
          <div
            className="navbar-toggler  p-0  border-0"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <i className="bi bi-list text-light display-1"></i>
          </div>
          <Link to={"/"}>
            {" "}
            <img src={Logo} width={"180px"} className="d-md-none " alt="" />
          </Link>
          <div
            className="offcanvas offcanvas-start  "
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            // style={{
            //   backgroundImage: "linear-gradient(to bottom, grey,white, white)",
            // }}
          >
            <div className="offcanvas-header bg-dark pacity-75">
              <div className="offcanvas-title m-0" id="offcanvasNavbarLabel ">
                <img
                  src={Logo}
                  style={{ width: "60%", height: "auto" }}
                  alt=""
                />
              </div>
              <a
                href="/#"
                ref={closebtn}
                type="button"
                className="fs-4"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <i className="bi bi-x-square-fill"></i>
              </a>
            </div>
            <div className="offcanvas-body ">
              <ul className="navbar-nav justify-content-start flex-grow-1 ps-md-4 gap-1 gap-md-4  ">
                {NavItems.map((item, idx) => {
                  return (
                    <li className="nav-item user-select-none fs-5 " key={idx}>
                      <HashLink
                        to={item.link}
                        className={[
                          "nav-link ",
                          +Context.loginTab === +idx ? " isactive " : " ",
                        ].join(" ")}
                        aria-current="page"
                        // href="/"

                        onClick={() => {
                          Context.ChangeTab(idx);
                          closebtn.current.click();
                        }}
                      >
                        {item.text}
                      </HashLink>
                      <hr className="my-0 d-block d-md-none" />
                    </li>
                  );
                })}
              </ul>
              <div className=" d-flex flex-column  mt-4 pt-3  d-block d-md-none">
                <HashLink
                  // data-bs-dismiss="offcanvas"
                  to={"/#map"}
                  className="text-decoration-none text-dark"
                  onClick={() => {
                    closebtn.current.click();
                    Context.ChangeTab(0);
                  }}
                >
                  <div className=" text-center">
                    <i className="bi bi-house-fill fs-1  text-primary"></i>
                  </div>
                  <div>
                    <div className="text-center  mb-0 px-5">
                      {address.address}
                    </div>
                    <div className="text-center  text-primary">View Map</div>
                  </div>
                </HashLink>
                <div className="  text-center mt-3">
                  <i className="fs-1 me-2 text-primary  bi bi-telephone-fill"></i>
                </div>
                <div className="   text-center ">
                  <div className=" mb-0"> Call us now</div>
                  <a
                    onClick={() => {
                      closebtn.current.click();
                      Context.ChangeTab(0);
                    }}
                    href={"tel:" + address.phone}
                    style={{ textDecoration: "none" }}
                    className="text-primary"
                  >
                    {" "}
                    <div className="p-0 m-0 ">{address.phone}</div>
                  </a>
                </div>
              </div>
              <div className="fixed-bottom d-block d-sm-none text-center small fw-light">
                <small>version:{process.env.REACT_APP_VERSION}</small>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
