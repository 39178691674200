import { useState } from "react";
import AppContext from "./appcontext";
// import Snackbar from "./snackbar";

const UserContextProvider = ({ children }) => {
  const [loginTab, setLoginTab] = useState(0);
  const tabHandler = (val) => {
    setLoginTab(val);
  };

  return (
    <AppContext.Provider
      value={{
        loginTab,
        ChangeTab: tabHandler,
      }}
    >
      <div className="bg-nfo vh-100">{children}</div>
    </AppContext.Provider>
  );
};

export default UserContextProvider;
