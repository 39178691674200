import React from "react";
import Nurse1 from "../../assets/section1-img-a.jpg";
import MedSupport from "../../assets/section1-img-b.jpg";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
const Section1a = () => {
  const ItemList = [
    {
      image: Nurse1,
      title: "Are you a Medical Support Worker?",
      text: (
        <span>
          {" "}
          Are you a Nurse or a Support worker looking for a Job? Browse through
          our list of jobs and submit your CV
        </span>
      ),
      linkText: "Apply Now",
      link: "/jobs",
      action: "Need Job ?",
    },
    {
      image: MedSupport,
      title: "Looking for Medical Support Worker?",
      text: (
        <span>
          Are you a Healthcare Service looking for staff or need Medical Support
          Worker for yourself or family/ friend?
        </span>
      ),
      linkText: "Enquire Now",
      link: "/contactus",
      action: "Need Staff ?",
    },
  ];
  const random1 = Math.floor(Math.random() * ItemList.length);
  const random2 = random1 === 1 ? 0 : 1;

  return (
    <div>
      <div className="d-none d-sm-block">
        <div className="row row-cols-sm-2 my-d-3 ps-m-3 g-0 my-4 ">
          {CardSM(ItemList[random1])}
          {CardSM(ItemList[random2])}
        </div>
      </div>
      <div className="d-block d-sm-none">
        <div className="row row-cols-sm-2 my-d-3 ps-m-3 g-0 my-4 ">
          {Card(ItemList[random1])}
          {Card(ItemList[random2])}
        </div>
      </div>
    </div>
  );
};

function Card(param) {
  return (
    <div className="">
      <div
        className="text-bg-priary text-center overflow-hidden bg-light "
        style={{ height: "50vh" }}
        // style={{ height: "100%" }}
      >
        <div
          className="bg-body-tertiary shadow-sm mx-auto shadow shadow-3 border border-1"
          style={{ width: "90%" }}
        >
          {/* <img src={param.image} className="img-fluid w-100" /> */}
          <Parallax
            blur={{ min: -1, max: 2 }}
            bgImage={param.image}
            bgImageAlt="the dog"
            strength={+100}
            renderLayer={(percentage) => (
              <div
                style={{
                  position: "absolute",
                  background: `rgba(0, 0, 0, .3)`,
                  // left: '50%',
                  // top: '50%',
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          >
            {/* <div style={{ height: "100%" }} /> */}
            <div className="" style={{ height: "40vh" }}>
              <div className=" w-100 text-light display-6 position-absolute  start-50 translate-middle-x top-50   text-shadow-black">
                {param.action}
              </div>
              <div
                className=" position-absolute start-50 translate-middle-x "
                style={{ top: "80%" }}
              >
                <Link
                  to={param.link}
                  className="py btn btn-outline-warning   mt-3 shadow rounded-0 
                shadow shadow-3 fw-bold
                "
                >
                  {param.linkText}
                </Link>
              </div>
            </div>
          </Parallax>
        </div>
        <div className="my- px-3  " style={{ height: "10vh" }}>
          <p className="lead">{param.text}</p>
        </div>
      </div>
    </div>
  );
}

function CardSM(param) {
  return (
    <div className="">
      <div
        className="text-bg-priary text-center overflow-hidden bg-light "
        style={{ height: "80vh" }}
        // style={{ height: "100%" }}
      >
        <div
          className="bg-body-tertiary shadow-sm mx-auto shadow shadow-3 border border-1"
          style={{ width: "90%" }}
        >
          {/* <img src={param.image} className="img-fluid w-100" /> */}
          <Parallax
            blur={{ min: -1, max: 1 }}
            bgImage={param.image}
            bgImageAlt="the"
            strength={-100}
            renderLayer={(percentage) => (
              <div
                style={{
                  position: "absolute",
                  background: `rgba(0, 0, 0, .3)`,
                  // left: '50%',
                  // top: '50%',
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          >
            {/* <div style={{ height: "100%" }} /> */}
            <div className="" style={{ height: "70vh" }}>
              <div className=" w-100 text-light display-6 position-absolute  start-50 translate-middle-x top-50   text-shadow-black">
                {param.action}
              </div>
              <div
                className=" position-absolute start-50 translate-middle-x "
                style={{ top: "80%" }}
              >
                <Link
                  to={param.link}
                  className="py- btn btn-outline-warning   mt-3 shadow rounded-0 
                shadow shadow-3 fw-bold 
                "
                >
                  {param.linkText}
                </Link>
              </div>
            </div>
          </Parallax>
        </div>
        <div className="my- py- px-5  " style={{ height: "10vh" }}>
          <p className="lead">{param.text}</p>
        </div>
      </div>
    </div>
  );
}

export default Section1a;
