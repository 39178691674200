import React from "react";
import { Link } from "react-router-dom";
import { address } from "../../api/vars";

const Homebannertext = () => {
  return (
    <div
      className="bg-inf user-select-none d-flex align-items-center flex-column  text-white position-absolute  start-50 text-center translate-middle-x"
      style={{ width: "100%", top: "45%", height: "50vh" }}
    >
      <div className="">
        {/* <div className=" position-relative   mt-0 pt-0 "> */}
        <div className=" display-3 fw-bold text-white   text-shadow-black ">
          Making a <span className="text-warning">Difference</span>
        </div>

        <div
          className="position-absolte   text-end   w-100  "
          // style={{ top: -20 }}
        >
          <div className="h5 fs-3 fw-light animate__animated animate__fadeInDown animate__delay-1s text-shadow-black  text-white fst-italic">
            For the Nurses, By the Nurses
          </div>
        </div>
      </div>
      <div className="text-white mt-4  text-shadow-b text-spacing-1">
        <div className="h4 text-shadow-black fs-1 px-2">
          Caring approach redefined for service and quality by{" "}
          <br className="d-block " />
          <b className=" text-primary">NeedHands Nursing Agency</b>
        </div>
      </div>
      <div className="mt-auto p-2 text-white  text-center mb-2 phone">
        <Link to={"tel:" + address.phone}>
          <button className=" btn  btn-outline-warning phone fw-bold rounded-0  px-3 py-2 py-md- mb-3 shadow shadow-4 ">
            <i className="fs-4 me-2   bi bi-telephone-fill  vibrate "></i>
            {address.phone}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Homebannertext;
