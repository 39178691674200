import React, { useState, useEffect } from "react";
import Team1 from "../../assets/team-01.jpeg";
import Team2 from "../../assets/team-02.jpeg";
import Team3 from "../../assets/team-03.jpeg";
import Team4 from "../../assets/team-04.jpeg";

const Section5 = () => {
  const [windowWidth, setWindowWidth] = useState(1000);

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function MakeCard(param) {
    return (
      <div className="p-1 shadow ">
        <div
          className="col card border-2 border-start-0 border-bottom-0 border-end-0 border-warning  rounded-0 bg-light   "
          style={{ height: "100%" }}
        >
          <div className="card-body py-0 d-flex flex-column">
            <div className="card-title fs-5 fw-light pt-3">{param.title}</div>
            <div className="d-flex justify-content-start p-2 mt-auto">
              <div className="my-auto">
                {param.image && (
                  <img
                    // style={{ height: "40px" }}
                    src={param.image}
                    alt="..."
                    width={"60px"}
                    height={"auto"}
                    className="rounded-circle  img-fluid "
                  />
                )}
              </div>
              <div className="col my-auto ms-4 ">
                <div className="fs-4  fw-bold">{param.name}</div>
                <div
                  className="fs-5 mt-0 fw-light "
                  style={{ lineHeight: "80%" }}
                >
                  {" "}
                  {param.relation}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="user-select-none">
      <div className=" mx-2 pt-2 ">
        <div
          className=" display-5  "
          style={{ fontSize: (windowWidth + 400) / 36 }}
        >
          Great Story{" "}
          <span className="text-warning text-shadow-black  ">
            Means Everything
          </span>
        </div>

        <p
          className="mb-0  small   text-shadow-grey my-"
          style={{ fontSize: (windowWidth + 1000) / 100 }}
        >
          Our Senior Citizens For Whom We’re Here
        </p>

        <div className=" mt-1 text-end">
          <a
            href="/#"
            type="button"
            className="bn btn-warnig btn-sm rounded-0 px-  text-middle "
          >
            Read all stories
          </a>
        </div>
      </div>
      <div className="row row-cols-sm-3  g-3">
        {MakeCard(itemList[0])}
        {MakeCard(itemList[1])}
        {MakeCard(itemList[2])}
        {/* {MakeCard(itemList[3])} */}
        {/* {MakeCard()} */}
        {/* {MakeCard()} */}
        {/* {MakeCard()} */}
      </div>
    </div>
  );
};

const itemList = [
  {
    image: "",
    title: (
      <span>
        Everything worked out so well. The support workers from NeedHands were
        so friendly, and I have to say that my dear mother's care was exactly
        the way she wanted it to be.
      </span>
    ),
    name: "Viki Terrence",
    relation: "Resident’s daughter",
  },
  {
    image: "",
    title: (
      <span>
        We needed someone to look after my father while we were away but
        NeedHands answered our needs. I'm sure we will now continue to have a
        support worker from Needhands for his care.
      </span>
    ),
    name: "Jaco Acrobovich",
    relation: "Resident's son",
  },
  {
    image: "",
    title: (
      <span>
        Five stars all the way! Can’t praise them enough, honestly! Highly
        Recommended. Needhands Team was very supportive and listened to our
        needs very carefully and delivered an arrangement exactly how we wanted
        where no one could help us.
      </span>
    ),
    name: "Christin",
    relation: "Resident's brother",
  },
];
export default Section5;
