import React, { useRef } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../home/home.css";
import Logo from "../../assets/nh-logo-transparent-sm.png";
import NavBar from "./navbar";
import { address } from "../../api/vars";
const TopPanel = (props) => {
  const Navigate = useNavigate();
  return (
    <div>
      <div
        className="py-1 border-bottom border-secondary align-middle d-none d-md-block d-flex user-select-none"
        style={{
          backgroundImage: "linear-gradient(black,   transparent)",
        }}
        // style={{ height: "80px" }}
      >
        <div
          className="bg-inf d-flex justify-content-between my-auto py-2 mx-3"
          //   style={{ height: "100%" }}
        >
          <div className="my-auto">
            <img
              src={Logo}
              width={"190px"}
              height={"60px"}
              className="my-auto "
              alt=""
            />
          </div>
          <div className="fw-light  my-auto  d-flex ">
            <div className="fw-light  my-auto h2  d-flex">
              <i className="bi bi-facebook  me-3 text-info"></i>
              <i className="bi bi-twitter  me-3  text-primary"></i>
              <i className="bi bi-linkedin  me-3  text-info"></i>
            </div>
          </div>
          <div className="text-white my-auto h5 bg-ino d-flex">
            <div className="me-5  d-flex">
              <div className="  my-auto">
                <i className="bi bi-house-fill display-6  me-2 text-warning"></i>
              </div>
              <div>
                <HashLink
                  to="/#map"
                  className="text-decoration-none text-white"
                >
                  <div className="m-0 p-0 ">Our location</div>
                  <div className="p-0 m-0 ">View on Map</div>
                </HashLink>
              </div>
            </div>
            <div className="  d-flex">
              <div className="  my-auto">
                <i className="display-6 me-2 text-warning  bi bi-telephone-fill"></i>
              </div>
              <div>
                <div className="m-0 p-0  ">Call us now</div>
                <Link
                  to={"tel:" + address.phone}
                  style={{ textDecoration: "none" }}
                  className="text-warning "
                >
                  {" "}
                  <div className="p-0 m-0  text-warning ">{address.phone}</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" sticky-tp py-">
        <NavBar />
      </div>
      {props.children}
    </div>
  );
};

export default TopPanel;
