import React, { useState } from "react";
// import ContactForm from "./section8contactform";
import { Link, useNavigate } from "react-router-dom";
import { address } from "../../api/vars";
import { Mailer } from "../../api/mailer";
import ContactForm from "../contactus/contactform";
const Section8 = () => {
  const Navigate = useNavigate();
  const [contactData, setContactData] = useState({
    fname: "",
    lname: "",
    email: "",
    message: "",
  });

  const itemList = [
    {
      icon: "bi-telephone-fill",
      title: address.phone,
      text: "24/7 Emergency Phone, Call us Anytime",
      link: "/#",
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Address",
      text: address.address,
      link: "/#",
    },
    {
      icon: "bi-envelope-fill",
      title: "Email us",
      text: "info@needhands.com.au",
      link: "/#",
    },
    {
      icon: "bi-geo-alt-fill",
      title: "Address",
      text: address.address,
      link: "/#",
    },
  ];

  return (
    <div className="user-select-none" id="gettogether">
      <div className="row  ">
        <div className="col-12 col-lg-  pt- ">
          <div className="display-5    mb-4 ">
            Let’s get{" "}
            <span className="text-warning text-shadow-black">together</span>
          </div>
          <p
            className="text-dark-emphasis  fs-5"
            style={{ textAlign: "justify", textJustify: "inter-word" }}
          >
            Nursing is the noblest of the professions. When someone requires
            care during illness or old age, it is of utmost importance that the
            caregiver is passionate and truly caring in nature. When we choose
            our staff, the very first quality we ensure in them (obviously after
            the education and experience facts) is how passionate they are
            towards the profession. Needhands takes pride in providing the best
            nursing staff in each sector and is also honoured to have the best
            reviews from existing clients.
          </p>
          <div className="row row-cols-1   fs-5">
            <p
              className="text-primary-emphasis"
              style={{ textAlign: "justify", textJustify: "inter-word" }}
            >
              You can fill the <Link to={"/contactus"}>contact form</Link>,
              write the requirements and details of your enquiry and you will
              get an answer from our professionals within 24 hours and get a
              free consultation. If you have any additional questions about our
              work, we will be happy to send you our answers. We welcome all our
              clients in need of any information from our experts.
              <span className="float-end">
                <Link
                  to={"/contactus"}
                  className="btn btn-sm btn-warning text-dark  m-3 rounded-0 animate__animated  animate__pulse  animate__delay-1s animate__infinite animate__fast"
                >
                  Contact us
                </Link>
              </span>
            </p>
          </div>
          <div className="row row-cols-1 row-cols-md-3 ">
            {MakeCard(itemList[0])}
            {MakeCard(itemList[1])}
            {MakeCard(itemList[2])}
            {/* {MakeCard(itemList[3])} */}
          </div>
        </div>
        <div className="col-12 col-lg-5  p-3 bg-light " id="contactus"></div>
      </div>
    </div>
  );
};

export function MakeCard(param) {
  return (
    <div className="card border-0  px-  mb-3">
      <div className="card-body px-1  p-0">
        <div className="card-title  mb-0">
          <i className={`bi ${param.icon} fs-3 text-primary`}></i>
        </div>
        <p className="card-title  h6 fs-4 mb-0  ">{param.title}</p>
        <p className="card-text small fs-5">{param.text}</p>
      </div>
    </div>
  );
}

export default Section8;
