import React, { useState, useEffect } from "react";
import MedSupport from "../../assets/home_why_us.jpg";
import { Parallax } from "react-parallax";

const Section6 = () => {
  const [windowWidth, setWindowWidth] = useState(1000);

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const itemList = [
    {
      icon: "",
      title: "24 x 7 Availability",
      text: (
        <span>
          We are available 24 x 7 for your service. Whenever you need a medical
          staff, give us a call. <b>We always have backup ready.</b>
        </span>
      ),
      link: "/#",
    },
    {
      icon: "",
      title: "Verified staff",
      text: (
        <span>
          All our medical care staff are verified by our internal team for their
          required certifications and other requirements.
        </span>
      ),
      link: "/#",
    },
    {
      icon: "",
      title: "Friendly & experienced",
      text: (
        <span>
          We provide highly friendly and experienced medical staff at your
          service. So you are in the best hands with us.
        </span>
      ),
      link: "/#",
    },
    {
      icon: "",
      title: "No commitment",
      text: (
        <span>
          We provide both temporary or permanent medical aid staff as per your
          requirements.
        </span>
      ),
      link: "/#",
    },
    {
      icon: "",
      title: "Peace of mind",
      text: (
        <span>
          we take care of all your Nursing and medical staff requirements
          without worrying about payroll processing or insurance, we do it all.
        </span>
      ),
      link: "/#",
    },
    {
      icon: "",
      title: "Large Database",
      text: (
        <span>
          We have a large database of nursing staff, so you remain assured to
          get help anytime.
        </span>
      ),
      link: "/#",
    },
  ];

  return (
    <div className="user-select-none">
      <Parallax
        // className="borde bordr-2 border-light p-0"
        bgImage={MedSupport}
        blur={1}
        strength={+100}
        renderLayer={(percentage) => (
          <div
            className="w-100 "
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, .3`,
              // left: '50%',
              // top: '50%',
              width: "100%",
              height: "100%",
            }}
          />
        )}
      >
        <div className="" style={{ height: "100" }}>
          <div
            className="display-6 text-white text-shadow-black   ps-2 mb-0   pt-3 "
            style={{ fontSize: (windowWidth + 400) / 36 }}
          >
            Why You should{" "}
            <span className="text-warning text-shadow-black">Choose Us?</span>
          </div>

          <div className="row  g-">
            <div className="col-12 col-lg- py-5 px-5  pt- pt-lg-5">
              <div className="row row-cols-1 row-cols-md-2 ">
                {itemList.map((el, idx) => {
                  return <div key={idx}>{MakeCard(el)} </div>;
                })}
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
};

export function MakeCard(param) {
  return (
    <div className="car border-0 my-3">
      <div className="card-body">
        <div className="card-title ">
          <i className={`bi ${param.icon} fs-3 text-primary`}></i>
        </div>
        <div className="card-title mb-1 title  h2 text-light text-shadow-blak  text-shadow ">
          {param.title}
        </div>
        <div className="fw-light fs-5 text-white pe-4">
          {param.text} &nbsp;
          <a
            className="card-text link-primary"
            href={param.link}
            style={{ textDecoration: "none" }}
          >
            <small className="text-warning d-none">Read more ..</small>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Section6;
