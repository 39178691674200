import React from "react";
// import { useNavigate } from "react-router-dom";
import { address } from "../../api/vars";

const Section1 = () => {
  // const Navigate = useNavigate();

  return (
    <div className="mt-4">
      <section class="container">
        <div
          style={{
            float: "left",
            alignItems: "center",
          }}
          className="bg-ino  col-5 pe-3 me-4    text-center border-end"
        >
          <span className="display-3 text-warning text-shadow-black">
            {" "}
            Our Vision
          </span>
          <p className="fs-4 text-warning-emphasis">
            To improve health and well being of residents in need of health care
            by building a client centred team of Care providers including nurses
            in delivering optimal care to the residents.
          </p>
        </div>

        <p className="fs-4  " style={{ textAlign: "justify" }}>
          Nursing is the noblest of the professions. When someone requires care
          during illness or old age, it is of utmost importance that the
          caregiver is passionate and truly caring in nature. When we choose our
          staff, the very first quality we ensure in them (obviously after the
          education and experience facts) is how passionate they are towards the
          profession. Needhands takes pride in providing the best nursing staff
          in each sector and is also honoured to have the best reviews from
          existing clients.
        </p>
        <p className="fs-4  " style={{ textAlign: "justify" }}>
          Needhands is the institution where you will find experienced
          registered nurses. Our team consists of Elementary education nurses,
          Advanced Education nurses, and special nurses for disability support
          positions.
        </p>
      </section>
      <section className="container my-5 py-3">
        <p className="lead border-top border-bottom text-center mx-3">
          Needhands is known for providing trained, skilled and experienced
          nurses to help you when you need care. The 24-hour service we provide
          can be best described as 'flawless'.
        </p>
      </section>

      <section class="container">
        <p className="text-center display-4 text-warning text-shadow-grey">
          Our Aproach
        </p>
        <div
          style={{
            float: "right",
            alignItems: "center",
          }}
          className="bg-ino  col-5 pe-3 ms-4    text-center border-stat "
        >
          {/* <span className="display-3 text-warning text-shadow-black">
            {" "}
            Our Vision
          </span> */}
          <div className="h1 text-warning text-shadow-black">
            <ul class="list-group list-group-flush display- my-auto ">
              <li className="py-2 list-group-item text-warning">
                24/7 Support
              </li>
              <li className="py-2 list-group-item text-warning">
                High availability
              </li>
              <li className="py-2 list-group-item text-warning">
                Peace of mind
              </li>
              <li className="py-2 list-group-item text-warning">
                Qualified staff
              </li>
            </ul>
          </div>
        </div>

        <div>
          <lead className="h6 fs-4 mt-2">Understanding client needs</lead>
          <p className="fs-4">
            The nursing agency begins by understanding the needs of the client,
            including the type of care required, the setting in which care will
            be provided, and any specific patient needs or preferences.
          </p>{" "}
          <lead className="h6 fs-4 mt-2">Recruiting qualified nurses</lead>
          <p className="fs-4">
            The agency then recruits qualified and experienced nurses with the
            necessary skills and credentials to meet the client's needs.
          </p>
          <lead className="h6 fs-4 mt-2">Screening and training nurses</lead>
          <p className="fs-4">
            Before placing nurses with clients, the agency screens and trains
            them to ensure that they have the required competencies, including
            clinical skills, communication skills, and cultural competence.
          </p>
          <lead className="h6 fs-4 mt-2">Matching nurses with clients</lead>
          <p className="fs-4">
            Once nurses are screened and trained, the agency matches them with
            clients based on their skills, experience, and availability.
          </p>
          <lead className="h6 fs-4 mt-2"> Monitoring performances</lead>
          <p className="fs-4">
            The agency continuously monitors nurse performance to ensure that
            they are meeting the client's needs and providing high-quality care.
          </p>
          <lead className="h6 fs-4 mt-2">Providing support</lead>
          <p className="fs-4">
            The nursing agency provides ongoing support to nurses and clients to
            ensure that any issues or concerns are addressed promptly and
            efficiently.
          </p>
        </div>
      </section>

      <section className="container my-5">
        <div className="card border-0 bg-light">
          <div className="card-body">
            <div className="card-title h1 display-5  title ">Working Hours</div>
            <div className="card-text col-12  fw-light fs-3">
              Let’s get together and discuss how NeedHands can address your
              needs, your way. Just give us a call or a visit and we’ll make all
              of it happen. You can also book via the following working hours.
            </div>
          </div>
          <div className="container list-group list-group-flush ">
            <li className="list-group-item bg-light col">
              <div className="d-flex">
                <div className="my-auto me-3">
                  <i className="bi bi-geo-alt-fill display-3 text-primary"></i>
                </div>
                <div className="col mt-aut ">
                  <div className="h5 fs-3  small  my-0 ">
                    Visit us in person
                  </div>
                  <div className="fw-bol fs-5  ">{address.address}</div>
                </div>
              </div>
            </li>
            <li className="list-group-item bg-light col">
              <div className="d-flex">
                <div className="my-auto me-3">
                  <i className="bi bi-telephone-fill display-3 text-primary"></i>
                </div>
                <div className="col mt-auto ">
                  <div className="fs-3 h5 small  my-0 ">Call Us Anytime</div>
                  <div className=" fs-5 mt-auto">{address.phone}</div>
                </div>
              </div>
            </li>
            <li className="list-group-item bg-light">
              <div className="d-flex">
                <div className="my-auto me-3">
                  <i className="bi bi-envelope-fill display-3 text-primary"></i>
                </div>
                <div className="col mt-auto ">
                  <div className="fs-3 h5 small  my-0">Email Us</div>
                  <div className="fs-5 mt-auto">info@needhands.com.au</div>
                </div>
              </div>
            </li>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section1;
