import React from "react";
import Logo from "../../assets/nh-logo-transparent-sm.png";
import { NavItems } from "../navbar/navitems";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { address } from "../../api/vars";
const Footer = () => {
  const Servies = [
    {
      icon: "",
      text: "Aged care",
      link: "#footerservices",
      linkType: "no-hash",
    },
    {
      icon: "",
      text: "Acute care",
      link: "#footerservices",
      linkType: "no-hash",
    },
    {
      icon: "",
      text: "Disabled care",
      link: "#footerservices",
      linkType: "no-hash",
    },
    {
      icon: "",
      text: "Community care",
      link: "#footerservices",
      linkType: "no-hash",
    },
  ];

  const Locate = [
    {
      id: "footermaplink",
      icon: "bi-geo-alt-fill",
      text: address.address,
      link: "/#map",
      linkType: "hash",
    },
    {
      icon: " bi-telephone-fill ",
      text: address.phone,
      link: "tel:" + address.phone,
      linkType: "hash",
    },
    {
      icon: "bi-envelope-fill",
      text: "info@needhands.com.au",
      link: "mailto:info@needhands.com.au",
      linkType: "hash",
    },
  ];

  return (
    <div>
      <footer className="container py-5">
        <div className="row ">
          <div className="col-12 col-md ">
            <div className="row row-cols-2 row-cols-sm-1">
              <div>
                <img src={Logo} width={140} />
                <small className="d-block mb-3 text-warning ms-2 ">
                  &copy; 2018–2023
                </small>
              </div>
              <div>
                <div className=" fw-light text-light   mt-auto">
                  <h6>Follow us</h6>
                </div>
                <h5 className="fw-light df1 mt-auto">
                  <i className="bi bi-facebook h3 me-3 text-info"></i>
                  <i className="bi bi-twitter h3 me-3  text-info"></i>
                  <i className="bi bi-linkedin h3 me-3  text-info"></i>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-6 col-md text-white">
            <h5>Site map</h5>
            <ul className="list-unstyled text-small">
              {NavItems.map((el, idx) => {
                return (
                  <li className="list-group-item my-1" id={el.id} key={idx}>
                    {el.linkType === "hash" ? (
                      <HashLink to={el.link} style={{ textDecoration: "none" }}>
                        <div className="d-flex">
                          <div className="my-auto me-"></div>
                          <div className="text-warning">{el.text}</div>
                        </div>
                      </HashLink>
                    ) : (
                      <Link to={el.link} style={{ textDecoration: "none" }}>
                        <div className="d-flex">
                          <div className="my-auto me- "></div>
                          <div className="text-warning">{el.text}</div>
                        </div>
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-6 col-md text-white">
            <h5>Services</h5>
            <ul className="list-unstyled text-small">
              {Servies.map((el, idx) => {
                return (
                  <li className="list-group-item my-1" id={el.id} key={idx}>
                    {el.linkType === "hash" ? (
                      <HashLink to={el.link} style={{ textDecoration: "none" }}>
                        <div className="d-flex">
                          <div className="my-auto me-"></div>
                          <div className="text-warning">{el.text}</div>
                        </div>
                      </HashLink>
                    ) : (
                      <Link to={el.link} style={{ textDecoration: "none" }}>
                        <div className="d-flex">
                          <div className="my-auto me- "></div>
                          <div className="text-warning">{el.text}</div>
                        </div>
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-6 col-md text-white">
            <h5>Locate Us</h5>
            <ul className="list-unstyled text-small">
              {Locate.map((el, idx) => {
                return (
                  <li className="list-group-item my-1" id={el.id} key={idx}>
                    {el.linkType === "hash" ? (
                      <HashLink to={el.link} style={{ textDecoration: "none" }}>
                        <div className="d-flex">
                          <div className="my-auto me-"></div>
                          <div className="text-warning">{el.text}</div>
                        </div>
                      </HashLink>
                    ) : (
                      <Link to={el.link} style={{ textDecoration: "none" }}>
                        <div className="d-flex">
                          <div className="my-auto me- "></div>
                          <div className="text-warning">{el.text}</div>
                        </div>
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
