import React, { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { Link } from "react-router-dom";
import "./home.css";
import { address } from "../../api/vars";
const ShowMap = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const center = {
    lat: -32.9275848,
    lng: 151.7105471,
  };

  function MyMap() {
    // const center = useMemo(
    //   () => ({
    //     lat: -32.9275848,
    //     lng: 151.7105471,
    //   }),
    //   []
    // );
    if (!isLoaded) return <div>not loaded...</div>;
    return (
      <div className="map-container ">
        <GoogleMap
          zoom={15}
          center={{
            lat: -32.9275848,
            lng: 151.7105471,
          }}
          mapContainerClassName="map-container"
          options={{ scrollwheel: false }}
          defaultCenter={center}
          averageCenter={true}
        >
          <MarkerF position={center} label={address.address} />
        </GoogleMap>
        {/* Go back */}
      </div>
    );
  }

  // if (!isLoaded) return <div>loading...</div>;
  return (
    <div>
      <div className="">
        <MyMap />
        <div
          className={["text-end me-5 ", props.noLink ? "d-none " : ""].join(
            " "
          )}
        >
          <Link
            to={".."}
            type="button"
            className="btn btn-warning rounded-0 shadow"
          >
            Go back
          </Link>
        </div>
      </div>
    </div>
    // <div> {process.env.REACT_APP_GOOGLE_MAPS_API_KEY}</div>
  );
};

export default ShowMap;
