import React, { useState, useEffect } from "react";
import { address } from "../../api/vars";

const Bannertext = () => {
  const [windowWidth, setWindowWidth] = useState(1000);

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className=" ps-2 ps-sm-5 user-select-none d-flex align-items-cener flex-column  text-white position-absolute  start-0 "
      style={{ width: "100%", top: "45%", height: "50vh" }}
    >
      <div className="">
        <div
          className="display-5 fw-bold text-white   text-shadow-black "
          style={{ fontSize: (windowWidth + 350) / 25 }}
        >
          Take your <span className="text-warning">Passion</span> to work
        </div>

        {/* </div> */}
      </div>
      <div
        className="text-white mt-1   text-shadow-blac text-spacing-1 "
        // style={{ width: "60w" }}
      >
        <div
          className=" text-shadow-grey  "
          style={{
            fontSize: (windowWidth + 600) / 67,
          }}
        >
          We have job offers for you. <br className="d-block d-sm-none" /> Fill
          the form now !!
        </div>
      </div>
    </div>
  );
};

export default Bannertext;
