import React from "react";

const Spinner = (props) => {
  return (
    <div
      className={[
        "d-flex flex-column  align-items-center justify-content-center vh-100 top-0 fixed-top vh-100 bg-white bg-opacity-50",
        props.className,
      ].join(" ")}
      style={{ background: "rgba(76, 175, 80, 0.3)" }}
    >
      <div className="row justify-content-ceter">
        <div className="spinner-border text-primary" role="status"></div>
      </div>
      <div className="row justify-content-center mt-5">
        <strong>{props.text} </strong>
      </div>
    </div>
  );
};

export default Spinner;
